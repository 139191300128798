span.yellow {
  text-transform: uppercase;
  color: #FFDE59;
  white-space:nowrap;
  display: inline;
}
.gowhite {
  white-space:nowrap;
  display: inline;
}

.chardiy-full{
  margin-top: 95px;
  grid-template-columns: 100%;
  max-width: 100%;
  padding: 0;

  img {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1024px) {
    margin-top: 66px;
  }
}
.chardiy-full:hover{
  cursor: pointer;
}

// Nikolai
.radio-wrapper {
  div {
    display: block;
  }
}

.updateSection18aForm {
  margin-top: 60px;  

  a {
    background: #59C6C4;
    color: #F7F7F7;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    display: block;
    padding: 10px 30px;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    -webkit-appearance: none;
    border: 0;
    outline: none;    
    text-align: center;
    margin: 0 auto;
  }

  a:hover {
    background: #2a5d5c;
  }

  form {
    width: 50%;
    margin: 0 auto;

    .select-dropdown-arrow::after {
      top: 70%;
    }
  }

  form > div {
    margin: 20px 0;    
    margin: 20px auto; 
  }

  form label {
    font-size: 12px;
    font-weight: bold;
  }
}

// Custom styles for select element
.select-dropdown-arrow {
  position: relative;
  display: block;  

  select, input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 8px 30px 8px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      font-size: 16px;
      cursor: pointer;
      width: 100%;      
  }

  // Dropdown arrow icon
  &::after {
    content: '\25BC'; // Unicode for down arrow
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #0199CD;
}
}

.hidden {
  display: none;
}

.ticketsTable th,
.ticketsTable td {
    padding: 5px 15px !important;
    border: 1px solid black !important;    
}

.ticketWrapper {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 15px;
}